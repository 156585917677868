<template>
  <div class="d-flex justify-space-between align-center">
    <div style="width: 300px">
      <tp-search-option
        :default-option="searchType"
        :options="searchOptions"
        :value="searchKey"
        @search="onSearch"
      ></tp-search-option>
    </div>

    <v-btn
      :loading="statusRequest.value === 'loading-exportReportFile'"
      color="primary"
      depressed
      @click="exportData"
    >
      <v-icon left>mdi-file-download-outline</v-icon>
      Xuất file
    </v-btn>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { createVuexHelpers, exportExcelFile } from "@/core/composables";

export default defineComponent({
  props: {
    searchKey: { type: String, default: "" },
    searchType: { type: [String, Number] },
    params: { type: Object, default: () => ({}) }
  },

  setup(props, { emit }) {
    const { useState } = createVuexHelpers();

    const { statusRequest } = useState("FUND", ["statusRequest"]);

    const onSearch = data => {
      emit("update:searchKey", data.searchKey || null);
      emit("update:searchType", data.option || null);
    };

    return {
      statusRequest,
      onSearch
    };
  },

  data() {
    return {
      searchOptions: [
        { type: "phone", label: "Theo SDT khách hàng" },
        { type: "name", label: "Theo tên khách hàng" },
        { type: "related_code", label: "Theo mã chứng từ liên quan" },
        { type: "code", label: "Theo mã phiếu" }
      ]
    };
  },

  methods: {
    exportData() {
      exportExcelFile(this, {
        storeName: "FUND",
        payload: this.params,
        fileName: "quan_ly_thu_chi_"
      });
    }
  }
});
</script>
