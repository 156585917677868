<template>
  <div class="px-5 d-flex">
    <filter-area class="mb-3"></filter-area>

    <div class="ml-5 flex-grow-1 overflow-hidden">
      <top-action
        :params="state"
        :search-key.sync="state.searchKey"
        :search-type.sync="state.searchType"
        class="mb-4"
      ></top-action>

      <table-pagination
        :current-page.sync="state.page"
        :per-page.sync="state.perPage"
        class="mb-4"
      >
        <template #select-action>
          <div v-if="selectedIds.length > 0" class="d-flex align-center">
            <tp-btn-overflow
              :active-item="false"
              :items="actionList"
              btn-class="white rounded-lg"
              placeholder="Thao tác"
              @select="selectAction"
            ></tp-btn-overflow>

            <div class="ml-3">
              Đã chọn <strong>{{ selectedIds.length }} phiếu</strong>, tổng giá
              trị
              <strong>{{ selectedTotalAmount | formatCurrency }}</strong>
            </div>
          </div>
        </template>
      </table-pagination>

      <data-table-items
        :selectedIds.sync="selectedIds"
        @reload="refetch"
      ></data-table-items>
    </div>

    <modal-revenue-detail
      :key="$route.query.revenueId"
      @reload="refetch"
    ></modal-revenue-detail>
  </div>
</template>

<script>
import { defineComponent, reactive, watch } from "vue";
import { cloneDeep, isString, isEqual, omit, sum } from "@vt7/utils";

import DataTableItems from "./components/DataTableItems.vue";
import FilterArea from "./components/FilterArea.vue";
import TablePagination from "./components/TablePagination.vue";
import TopAction from "./components/TopAction.vue";

import { createVuexHelpers, useRouteQuery } from "@/core/composables";
import { getTimeByKey } from "@/core/components/general/InputTimeFilter/utils";
import ModalRevenueDetail from "./components/ModalRevenueDetail.vue";
import { useTransactionAction } from "@/modules/Fund/pages/FundRevenue/composables";

export default defineComponent({
  name: "revenue",

  components: {
    DataTableItems,
    FilterArea,
    TablePagination,
    TopAction,
    ModalRevenueDetail
  },

  setup() {
    const { useActions, useState } = createVuexHelpers();
    const { resolveTransaction } = useTransactionAction();

    const { getAllBranches } = useActions("BRANCH", ["getAllBranches"]);
    const { getAllEmployees } = useActions("EMPLOYEE", ["getAllEmployees"]);
    const { getRevenueList } = useActions("FUND", ["getRevenueList"]);
    const { transactions } = useState("FUND", ["transactions"]);

    getAllBranches();
    getAllEmployees();

    const state = reactive({
      page: useRouteQuery("currentPage", 1),
      perPage: useRouteQuery("perPage", 25),

      searchKey: useRouteQuery("searchKey", "", value => value || ""),
      searchType: useRouteQuery("searchType", "phone"),

      time: useRouteQuery("time", "full-time", value => {
        if (isString(value)) return getTimeByKey(value);

        return value;
      }),
      branches: useRouteQuery("branches", []),
      receiverableIds: useRouteQuery("receiverableIds", []),
      types: useRouteQuery("type", []),
      status: useRouteQuery("status", []),
      employees: useRouteQuery("employees", []),
      kind: useRouteQuery("kind", []),
      receiverBankAccountIds: useRouteQuery("receiverBankAccountIds", [])
    });

    const fetch = () => getRevenueList(state);

    watch(
      () => cloneDeep(state),
      (newValue, oldValue) => {
        if (isEqual(newValue, oldValue)) return;
        // reset currentPage về 1 khi thay đổi các state khác
        if (!isEqual(omit(newValue, "page"), omit(oldValue, "page"))) {
          state.page = 1;
        }
        fetch();
      },
      { immediate: true, deep: true }
    );

    return {
      state,
      refetch: fetch,
      transactions,
      getAllBranches,
      resolveTransaction
    };
  },

  data() {
    return {
      selectedIds: [],
      actionList: [{ id: "confirm", text: "Xác nhận" }]
    };
  },

  computed: {
    selectedTotalAmount() {
      const _selectedTransactions = this.transactions.filter(transaction =>
        this.selectedIds.includes(transaction.id)
      );

      return sum(
        _selectedTransactions,
        transaction => transaction.total_amount
      );
    }
  },

  methods: {
    onResolve() {
      const payload = {
        title: `<span>Xác nhận phiếu <strong>${this.selectedIds.length}</strong></span>`,
        message:
          "Hành động này sẽ không thể hoàn tác. Bạn có chắc chắn muốn tiếp tục?",
        confirmBtn: {
          color: "success accent-2",
          text: "Tiếp tục",
          onClickHandler: () =>
            this.resolveTransaction(this.selectedIds, () => {
              this.selectedIds = [];
              this.refetch();
            })
        }
      };

      this.$modal.show({
        name: "modal-alert",
        payload
      });
    },
    selectAction() {
      this.onResolve();
    }
  }
});
</script>
