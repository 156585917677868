<template>
  <v-data-table
    :headers="FUND_REVENUE_TABLE_HEADER"
    :items="transactions"
    :loading="statusRequest.value === 'loading-getRevenueList'"
    :server-items-length="paginationInfo.pageTotal"
    class="px-3 py-2 table-revenue tp-table-scroll"
    hide-default-footer
    hide-default-header
    item-key="id"
  >
    <template v-slot:header="{ props: { headers } }">
      <thead>
        <tr>
          <th>
            <v-checkbox
              v-model="isSelectAll"
              :disabled="!hasRole('admin')"
              @change="handleChange"
            ></v-checkbox>
          </th>
          <th v-for="(item, index) in headers" :key="index">
            {{ item.text }}
          </th>
        </tr>
      </thead>
    </template>

    <template v-slot:body="{ items }">
      <tbody v-if="items.length > 0">
        <tr class="yellow lighten-5">
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td class="font-weight-bold">
            {{ totalAmounts | formatCurrency }}
          </td>
          <td></td>
        </tr>

        <tr v-for="item in items" :key="item.id" @click="viewDetail(item)">
          <td>
            <v-checkbox
              v-model="internalSelectedIds"
              :disabled="
                REVENUE_STATUS.DANG_CHO !== item.sub_status || !hasRole('admin')
              "
              :value="item.id"
              @click.stop.prevent
            ></v-checkbox>
          </td>
          <td>{{ item.code }}</td>
          <td>{{ item.created_at }}</td>
          <td>{{ get(item, "created_by_user.name") }}</td>
          <td>{{ get(item, "branch.name") }}</td>
          <td>
            {{ getTextKind(item.kind) }}
            {{ getTextTypeAccount(get(item, "receiver_bank.type")) }}
          </td>
          <td>
            <div>
              {{ get(item, "senderable.name") }}
            </div>

            <div
              v-if="get(item, 'senderable.phone')"
              class="d-flex align-center"
            >
              {{ get(item, "senderable.phone") }}

              <v-btn
                color="blue darken-4"
                fab
                text
                x-small
                @click.stop="onCopy(item, 'senderable')"
              >
                <v-icon small>mdi-content-copy</v-icon>
              </v-btn>
            </div>
          </td>

          <td>
            <div>
              {{ get(item, "receiverable.name") }}
            </div>

            <div
              v-if="get(item, 'receiverable.phone')"
              class="d-flex align-center"
            >
              {{ get(item, "receiverable.phone") }}

              <v-btn
                color="blue darken-4"
                fab
                text
                x-small
                @click.stop="onCopy(item, 'receiverable')"
              >
                <v-icon small>mdi-content-copy</v-icon>
              </v-btn>
            </div>
          </td>

          <td class="receiver_bank">
            <div v-if="get(item, 'receiver_bank')">
              {{ get(item, "receiver_bank.number") }} -
              {{ get(item, "receiver_bank.name") }}
            </div>
          </td>

          <td>
            <button class="primary--text">
              {{ get(item, "transactionable.code") }}
            </button>

            <div class="mt-1">
              {{
                item.transactionable ? item.transactionable.status_label : ""
              }}
            </div>
          </td>

          <td>
            <div>{{ item.total_amount | formatCurrency }}</div>

            <div v-if="item.payment_fee" class="text-caption">
              Bao gồm phí: {{ item.payment_fee | formatCurrency }}
            </div>
          </td>

          <td class="data-table-revenue">
            <div
              :class="statusColors[item.sub_status]"
              class="text--darken-2 font-weight-medium"
            >
              {{ item.sub_status_label }}
            </div>
            <div class="text-body-2">{{ item.status_label }}</div>
          </td>
        </tr>
      </tbody>

      <tbody v-else>
        <tr class="v-data-table__empty-wrapper">
          <td :colspan="FUND_REVENUE_TABLE_HEADER.length">Không có dữ liệu</td>
        </tr>
      </tbody>
    </template>
  </v-data-table>
</template>

<script>
import { computed, defineComponent } from "vue";
import { get } from "@vt7/utils";
import { useVModel } from "@vueuse/core";

import { createVuexHelpers, usePermission } from "@/core/composables";

import { FUND_REVENUE_TABLE_HEADER } from "../../../constants";
import {
  getTextKind,
  getTextStatus,
  getTextType,
  getTextTypeAccount
} from "../utils";
import { REVENUE_STATUS } from "../constants";

export default defineComponent({
  props: {
    sortBy: { type: String },
    sortDesc: { type: Boolean },
    selectedIds: { type: Array, default: () => [] }
  },

  setup(props, { emit }) {
    const { useState } = createVuexHelpers();
    const { paginationInfo, transactions, statusRequest } = useState("FUND", [
      "paginationInfo",
      "transactions",
      "statusRequest"
    ]);

    return {
      ...usePermission(),
      internalSelectedIds: useVModel(props, "selectedIds", emit),
      paginationInfo,
      transactions,
      statusRequest,
      totalAmounts: computed(() => paginationInfo.value.total_amount)
    };
  },
  data() {
    return {
      isSelectAll: false,
      REVENUE_STATUS,
      FUND_REVENUE_TABLE_HEADER: FUND_REVENUE_TABLE_HEADER,
      statusColors: {
        1: "yellow--text",
        2: "green--text",
        3: "red--text"
      }
    };
  },
  methods: {
    get,
    getTextType,
    getTextKind,
    getTextStatus,
    getTextTypeAccount,
    onCopy(item, path) {
      const name = get(item, path + ".name");
      const phone = get(item, path + ".phone");

      this.copyToClipboard(`${name} ${phone}`);
    },
    viewDetail(item) {
      const route = {
        query: {
          ...this.$route.query,
          revenueId: item.id
        }
      };
      this.$router.push(route);
    },
    handleChange() {
      if (!this.isSelectAll) {
        this.internalSelectedIds = [];
      }
    }
  },

  computed: {
    allPendingTransactionIds() {
      return this.transactions
        .filter(item => REVENUE_STATUS.DANG_CHO === item.sub_status)
        .map(item => item.id);
    }
  },

  watch: {
    isSelectAll(val) {
      if (val) {
        this.internalSelectedIds = this.allPendingTransactionIds;
      }
    },
    internalSelectedIds(val) {
      if (val.length !== this.allPendingTransactionIds.length) {
        this.isSelectAll = false;
      }
    },
    paginationInfo: {
      handler() {
        this.isSelectAll = false;
        this.internalSelectedIds = [];
      },
      deep: true
    }
  }
});
</script>

<style>
.table-revenue .v-input--is-disabled {
  opacity: 0.4;
}
</style>
