<template>
  <div class="filter-area">
    <div class="mb-4">
      <span class="font-weight-bold text-h5"> Quản lý thu chi</span>
    </div>

    <v-card class="tp-filter-scroll pa-5" flat>
      <div>
        <div class="font-weight-bold mb-2">Thời gian</div>

        <tp-input-time-filter v-model="selectedTime"></tp-input-time-filter>
      </div>

      <div class="mt-4">
        <div class="font-weight-bold mb-2">Chi nhánh</div>

        <v-autocomplete
          v-model="selectedBranches"
          :items="branches"
          chips
          class="tp-filter-autocomplete"
          deletable-chips
          dense
          hide-details
          hide-selected
          item-text="name"
          item-value="id"
          label="Chọn chi nhánh"
          multiple
          no-data-text="Không có dữ liệu"
          outlined
          placeholder="Chọn chi nhánh"
          single-line
          small-chips
        ></v-autocomplete>
      </div>

      <div class="mt-5">
        <div class="font-weight-bold mb-2">Loại phiếu</div>

        <v-checkbox
          v-for="item in TYPES_OF_REVENUES"
          :key="'loai-phieu-' + item.value"
          v-model="selectedTypes"
          :label="item.text"
          :value="item.value"
          class="mt-1"
          dense
          hide-details
        ></v-checkbox>
      </div>

      <div class="mt-4">
        <div class="font-weight-bold mb-2">Loại thu chi</div>

        <v-autocomplete
          v-model="selectedKind"
          :items="KIND_OF_REVENUES"
          chips
          class="tp-filter-autocomplete"
          deletable-chips
          dense
          hide-details
          hide-selected
          item-text="text"
          item-value="value"
          label="Chọn loại thu chi"
          multiple
          no-data-text="Không có dữ liệu"
          outlined
          placeholder="Chọn loại thu chi"
          single-line
          small-chips
        ></v-autocomplete>
      </div>

      <div v-if="isShowReceiverAccount" class="mt-4">
        <div class="font-weight-bold mb-2">Tài khoản nhận</div>

        <v-autocomplete
          v-model="receiverBankAccountIds"
          :items="optionBankAccountFilter"
          chips
          class="tp-filter-autocomplete"
          deletable-chips
          dense
          hide-details
          hide-selected
          item-text="name"
          item-value="id"
          multiple
          no-data-text="Không có dữ liệu"
          outlined
          placeholder="Chọn tài khoản nhận"
          single-line
          small-chips
        >
        </v-autocomplete>
      </div>

      <div v-if="filteredSuppliers.length" class="mt-4">
        <div class="font-weight-bold mb-2">Đối tượng nhận</div>

        <v-autocomplete
          v-model="receiverableIds"
          :items="filteredSuppliers"
          chips
          class="tp-filter-autocomplete"
          deletable-chips
          dense
          hide-details
          hide-selected
          item-text="name"
          item-value="id"
          multiple
          no-data-text="Không có dữ liệu"
          outlined
          placeholder="Chọn đối tượng nhận"
          single-line
          small-chips
        ></v-autocomplete>
      </div>

      <div class="mt-5">
        <div class="font-weight-bold mb-2">Trạng thái</div>

        <v-checkbox
          v-for="item in REVENUE_STATUSES"
          :key="'trang-thai-' + item.value"
          v-model="selectedStatus"
          :label="item.text"
          :value="item.value"
          class="mt-1"
          dense
          hide-details
        ></v-checkbox>
      </div>

      <div class="mt-4">
        <div class="font-weight-bold mb-2">Nhân viên tạo</div>

        <v-autocomplete
          v-model="selectedEmployees"
          :items="employees"
          chips
          class="tp-filter-autocomplete"
          deletable-chips
          dense
          hide-details
          hide-selected
          item-text="name"
          item-value="id"
          label="Chọn nhân viên"
          multiple
          no-data-text="Không có dữ liệu"
          outlined
          placeholder="Chọn nhân viên"
          single-line
          small-chips
        ></v-autocomplete>
      </div>
    </v-card>
  </div>
</template>

<script>
import { computed, defineComponent, watch } from "vue";
import { useFetchData } from "@thinkvn/composables/lib/useFetchData";

import { createVuexHelpers, useRouteQuery } from "@/core/composables";
import {
  ACCOUNT_FILTERING_OPTIONS,
  KIND_OF_REVENUES,
  KIND,
  REVENUE_STATUSES,
  TYPES_OF_REVENUES
} from "../constants";
import supplierService from "@/modules/PartnerAndMember/service/supplier.service";
import { SUPPLIER_CATEGORY } from "@/modules/PartnerAndMember/constant";
import { isEqual } from "@vt7/utils";

export default defineComponent({
  setup() {
    const { useGetters } = createVuexHelpers();
    const { allSaleBranches: branches } = useGetters("BRANCH", [
      "allSaleBranches"
    ]);
    const { allEmployees: employees } = useGetters("EMPLOYEE", [
      "allEmployees"
    ]);

    const selectedTime = useRouteQuery("time", "full-time");
    const selectedBranches = useRouteQuery("branches", []);
    const selectedTypes = useRouteQuery("type", []);
    const selectedStatus = useRouteQuery("status", []);
    const selectedEmployees = useRouteQuery("employees", []);
    const selectedKind = useRouteQuery("kind", []);
    const receiverableIds = useRouteQuery("receiverableIds", []);
    const receiverBankAccountIds = useRouteQuery("receiverBankAccountIds", []);

    watch(
      () => selectedKind.value,
      (newValue, oldValue) => {
        if (isEqual(newValue, oldValue)) return;

        receiverBankAccountIds.value = [];
      }
    );

    const { data: suppliers, fetch } = useFetchData(
      () => supplierService.getAllSuppliers(),
      {
        transform: ({ data }) => data.data,
        initialData: []
      }
    );

    const filteredSuppliers = computed(() => {
      const targetCategories = [
        SUPPLIER_CATEGORY.VAN_CHUYEN,
        SUPPLIER_CATEGORY.TRUNG_GIAN_THANH_TOAN,
        SUPPLIER_CATEGORY.TRA_GOP,
        SUPPLIER_CATEGORY.CONG_THANH_TOAN
      ];

      return suppliers.value.filter(supplier => {
        return supplier.sup_cates.some(item =>
          targetCategories.includes(item.id)
        );
      });
    });

    const isShowReceiverAccount = computed(() => {
      const selectedKindLength = selectedKind.value.length;
      const kind = selectedKindLength === 1 ? selectedKind.value[0] : null;

      return selectedKindLength === 1 && kind === KIND.THU_CHUYEN_KHOAN;
    });

    fetch();

    return {
      branches,
      employees,
      selectedTime,
      selectedBranches,
      selectedTypes,
      selectedStatus,
      selectedEmployees,
      selectedKind,
      receiverableIds,
      filteredSuppliers,
      TYPES_OF_REVENUES,
      REVENUE_STATUSES,
      KIND_OF_REVENUES,
      ACCOUNT_FILTERING_OPTIONS,
      isShowReceiverAccount,
      receiverBankAccountIds
    };
  },

  async created() {
    await this.$store.dispatch("BANK_ACCOUNT/getCompanyBankAccounts");
  },

  computed: {
    optionBankAccountFilter() {
      const accounts = this.$store.getters["BANK_ACCOUNT/companyBankAccounts"];

      return [
        ...ACCOUNT_FILTERING_OPTIONS,
        { divider: true },
        ...accounts
          .filter(account => account.enable)
          .map(item => ({ name: item.name, id: item.id }))
      ];
    }
  }
});
</script>

<style lang="scss" scoped>
.filter-area {
  position: sticky;
  top: 80px;
  height: 100%;
  width: 256px;
}
</style>
