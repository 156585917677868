import FundService from "@/modules/Fund/service";
import { getCurrentInstance, ref } from "vue";

export const useTransactionAction = () => {
  const instance = getCurrentInstance().proxy;

  const isLoading = ref(false);

  const rejectTransaction = async (item, cancelReason) => {
    isLoading.value = true;

    try {
      await FundService.cancelTransaction({
        transaction_id: item.id,
        note: cancelReason
      });

      instance.$toast.show({
        name: "toast-action-alert",
        payload: {
          message: "Đã hủy phiếu thành công"
        }
      });

      instance.$emit("reload");
    } catch (e) {
      const message = e?.response?.data || "Huỷ phiếu không thành công";

      instance.$modal.show({
        name: "modal-alert",
        payload: {
          title: `<span class="red--text text--accent-2">${message}</span>`,
          cancelBtnText: "OK"
        }
      });
    } finally {
      isLoading.value = false;
    }
  };

  const onReject = item => {
    const payload = {
      title: `<span>Hủy phiếu <strong>${item.code}</strong></span>`,
      message:
        "Hành động này sẽ không thể hoàn tác. Bạn có chắc chắn muốn tiếp tục?",
      onSubmitHandler: cancelReason => rejectTransaction(item, cancelReason)
    };

    instance.$modal.show({
      name: "modal-cancel",
      payload
    });
  };

  const resolveTransaction = async (ids, callback) => {
    isLoading.value = true;

    try {
      await FundService.resolveTransaction({
        transactions: ids.map(id => ({ id }))
      });

      instance.$toast.show({
        name: "toast-action-alert",
        payload: {
          message: "Đã xác nhận phiếu thành công"
        }
      });

      instance.$emit("reload");
      callback && callback();
    } catch (e) {
      console.log("e", e);
      const message = e?.response?.data || "Xác nhận phiếu không thành công";

      instance.$modal.show({
        name: "modal-alert",
        payload: {
          title: `<span class="red--text text--accent-2">${message}</span>`,
          cancelBtnText: "OK"
        }
      });
    } finally {
      isLoading.value = false;
    }
  };

  const onResolve = (item, callback) => {
    const payload = {
      title: `<span>Xác nhận phiếu <strong>${item.code}</strong></span>`,
      message:
        "Hành động này sẽ không thể hoàn tác. Bạn có chắc chắn muốn tiếp tục?",
      confirmBtn: {
        color: "success accent-2",
        text: "Tiếp tục",
        onClickHandler: () => resolveTransaction([item.id], callback)
      }
    };

    instance.$modal.show({
      name: "modal-alert",
      payload
    });
  };

  return {
    isLoading,
    onReject,
    onResolve,
    resolveTransaction
  };
};
