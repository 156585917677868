import dayjs from "dayjs";

const DEFAULT_FORMAT = "YYYY-MM-DD";

export const TIME_OPTIONS = [
  { id: "full-time", name: "Toàn thời gian", value: [] },
  {
    id: "today",
    name: "Hôm nay",
    value: [dayjs().format(DEFAULT_FORMAT), dayjs().format(DEFAULT_FORMAT)]
  },
  {
    id: "yesterday",
    name: "Hôm qua",
    value: [
      dayjs()
        .subtract(1, "day")
        .format(DEFAULT_FORMAT),
      dayjs()
        .subtract(1, "day")
        .format(DEFAULT_FORMAT)
    ]
  },
  {
    id: "this-week",
    name: "Tuần này",
    value: [
      dayjs()
        .startOf("week")
        .format(DEFAULT_FORMAT),
      dayjs()
        .endOf("week")
        .format(DEFAULT_FORMAT)
    ]
  },
  {
    id: "last-week",
    name: "Tuần trước",
    value: [
      dayjs()
        .subtract(1, "week")
        .startOf("week")
        .format(DEFAULT_FORMAT),
      dayjs()
        .subtract(1, "week")
        .endOf("week")
        .format(DEFAULT_FORMAT)
    ]
  },
  {
    id: "this-month",
    name: "Tháng này",
    value: [
      dayjs()
        .startOf("month")
        .format(DEFAULT_FORMAT),
      dayjs()
        .endOf("month")
        .format(DEFAULT_FORMAT)
    ]
  },
  {
    id: "last-month",
    name: "Tháng trước",
    value: [
      dayjs()
        .subtract(1, "month")
        .startOf("month")
        .format(DEFAULT_FORMAT),
      dayjs()
        .subtract(1, "month")
        .endOf("month")
        .format(DEFAULT_FORMAT)
    ]
  }
];

export const getTimeByKey = key => {
  const time = TIME_OPTIONS.find(item => item.id === key);

  return time ? time.value : [];
};
