export const FUND_REVENUE_TABLE_HEADER = [
  { text: "Mã phiếu", align: "start", value: "code" },
  {
    text: "Thời gian tạo",
    align: "start",
    sortable: false,
    value: "created_at"
  },
  { text: "Người tạo", value: "created_by_user.name", sortable: false },
  { text: "Chi nhánh tạo", value: "fat" },
  { text: "Loại thu chi", value: "kind", sortable: false },
  { text: "Đối tượng gửi", value: "senderable.name", sortable: false },
  { text: "Đối tượng nhận", value: "receiverable.name" },
  { text: "Tài khoản ngân hàng", value: "receiver_bank" },
  { text: "Chứng từ", value: "transactionable.code" },
  { text: "Giá trị", value: "total_amount" },
  { text: "Trạng thái", value: "status", align: "center" }
];

export const FUND_REVENUE_STATUS = [
  { label: "Chờ xác nhận", value: "unpaid" },
  { label: "Đã xác nhận", value: "cancel" }
];
