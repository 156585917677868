<template>
  <div v-if="paginationInfo" class="d-flex justify-space-between">
    <div class="d-flex">
      <tp-btn-overflow
        :items="showFilterList"
        :selected-value="perPage"
        btn-class="mr-5 white rounded-lg"
        @select="event => $emit('update:perPage', event.id)"
      ></tp-btn-overflow>

      <slot name="select-action"></slot>
    </div>

    <div class="action-row--col d-flex align-center">
      <div v-if="paginationInfo.itemTotal" class="text-body-2">
        {{ paginationInfo.from }} - {{ paginationInfo.to }} trong số
        {{ paginationInfo.itemTotal }}
      </div>

      <v-pagination
        :length="paginationInfo.pageTotal"
        :total-visible="5"
        :value="currentPage"
        @input="event => $emit('update:currentPage', event)"
      ></v-pagination>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { createVuexHelpers } from "@/core/composables";

export default defineComponent({
  props: {
    currentPage: { type: Number },
    perPage: { type: Number }
  },

  setup() {
    const { useState } = createVuexHelpers();

    const { paginationInfo } = useState("FUND", ["paginationInfo"]);

    return {
      paginationInfo,
      showFilterList: [
        { id: 25, text: "Hiển thị 25" },
        { id: 50, text: "Hiển thị 50" },
        { id: 100, text: "Hiển thị 100" }
      ]
    };
  }
});
</script>
