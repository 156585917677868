import {
  KIND_OF_REVENUES,
  REVENUE_STATUSES,
  TYPES_OF_REVENUES,
  TYPE_ACCOUNT_VALUE
} from "../constants";

const getText = collections => {
  return value => {
    const activatedItem = collections.find(item => item.value === value);

    return activatedItem ? activatedItem.text : "";
  };
};

export const getTextType = getText(TYPES_OF_REVENUES);

export const getTextKind = getText(KIND_OF_REVENUES);

export const getTextStatus = getText(REVENUE_STATUSES);

export const getTextTypeAccount = getText(TYPE_ACCOUNT_VALUE);
