<template>
  <div>
    <div class="font-weight-medium">{{ title }}</div>

    <template v-if="type === LIST_MODEL_TYPES.USER">
      <div class="mt-1">
        <button class="primary--text" @click="viewDetail(item)">
          {{ item.code }}
        </button>
      </div>

      <div class="mt-1">
        {{ item.name }}
        <template v-if="item.phone"> - {{ item.phone }}</template>
      </div>
    </template>

    <template v-if="type === LIST_MODEL_TYPES.SUPPLIER">
      <div class="mt-1">{{ item.code }} - {{ item.name }}</div>
    </template>

    <template v-if="type === LIST_MODEL_TYPES.COMPANY">
      <div class="mt-1">
        Công ty
      </div>

      <div class="mt-1">{{ item.name }} - {{ item.number }}</div>
    </template>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { LIST_MODEL_TYPES } from "@/core/constant";

export default defineComponent({
  props: {
    title: {
      type: String,
      default: ""
    },
    item: {
      type: Object,
      required: true
    },
    type: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      LIST_MODEL_TYPES
    };
  },

  methods: {
    async viewDetail(item) {
      await this.$store.dispatch("CUSTOMER/getCustomerById", item.id);
      await this.$store.dispatch("ORDER/getOrderPaymentTransactions", item.id);

      this.$modal.show({
        name: "modal-customer"
      });
    }
  }
});
</script>
