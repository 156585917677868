<template>
  <v-dialog v-model="visible" :width="'800px'" max-width="90%">
    <v-card v-if="isFetching" class="py-2">
      <v-card-text>
        Đang tải dữ liệu

        <v-progress-linear
          class="mb-0"
          color="primary"
          indeterminate
        ></v-progress-linear>
      </v-card-text>
    </v-card>

    <template v-else-if="data">
      <v-toolbar
        class="px-5 pb-0 align-start"
        extension-height="40px"
        flat
        height="56x"
      >
        <v-toolbar-title class="font-weight-bold">
          {{ data.code }} - {{ getTextKind(data.kind) }}
          {{ get(data, "created_by_user.name") }}
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn
          v-if="isShowButtonReject"
          :loading="isLoading"
          class="mr-2 red lighten-5 red--text"
          depressed
          @click="onReject(data)"
        >
          Huỷ
        </v-btn>
        <v-btn
          v-if="isShowButtonConfirm"
          :loading="isLoading"
          class="mr-2 primary lighten-5 primary--text"
          depressed
          @click="handleResolveData"
        >
          Xác nhận
        </v-btn>
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-container class="grey lighten-3 pa-5 black--text" fluid>
        <v-card class="text-body-1" flat>
          <div class="px-5 py-4">
            <div class="flex">
              <div class="w-1/2">Thời gian tạo: {{ data.created_at }}</div>
              <div class="w-1/2">
                Trạng thái:
                <v-chip
                  :color="statusColors[data.sub_status]"
                  outlined
                  small
                  style="margin-text-outline: -8px;"
                >
                  {{ data.sub_status_label }}
                </v-chip>
              </div>
            </div>

            <div class="mt-3 flex">
              <div class="w-1/2">
                <span v-if="get(data, 'branch.name')">
                  Chi nhánh thanh toán: {{ get(data, "branch.name") }}
                </span>
              </div>
              <div class="w-1/2">
                Chứng từ:
                <button
                  class="primary--text"
                  @click="
                    viewDetailTransactionable(
                      data.transactionable,
                      data.transactionable_type
                    )
                  "
                >
                  {{ get(data, "transactionable.code") }}
                </button>
              </div>
            </div>

            <hr class="my-3" />

            <div class="flex">
              <object-information
                :item="data.senderable"
                :type="data.senderable_type"
                class="w-1/2"
                title="Đối tượng gửi"
              ></object-information>

              <object-information
                :item="data.receiverable || { ...data.receiver_bank }"
                :type="data.receiverable_type"
                class="w-1/2"
                title="Đối tượng nhận"
              ></object-information>
            </div>

            <hr class="my-3" />

            <div class="flex">
              <div class="w-1/2">
                <div class="font-weight-medium">Tổng kết</div>

                <div class="mt-1">
                  Số tiền (không bao gồm phí TT):
                  {{ data.amount | formatCurrency }}
                </div>

                <div v-if="data.payment_fee" class="mt-1">
                  Phí thanh toán: {{ data.payment_fee | formatCurrency }}
                </div>
              </div>

              <div class="w-1/2">
                <div class="font-weight-medium">
                  Giá trị phiếu (bao gồm cả phí TT)
                </div>

                <div class="mt-1">
                  <span class="red--text font-weight-medium">
                    {{ data.total_amount | formatCurrency }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </v-card>
      </v-container>
    </template>
  </v-dialog>
</template>

<script>
import { computed, defineComponent, getCurrentInstance, watch } from "vue";
import { useFetchData } from "@thinkvn/composables/lib/useFetchData";
import { get } from "@vt7/utils";
import { eagerComputed } from "@vueuse/core";
import dayjs from "dayjs";
import isToday from "dayjs/plugin/isToday";

dayjs.extend(isToday);

import { useRouteQuery, usePermission } from "@/core/composables";

import TransactionService from "../../../service";
import { REVENUE_STATUS, KIND, TYPES } from "../constants";
import { useTransactionAction } from "../composables";
import ObjectInformation from "./ObjectInformation.vue";
import { getTextKind, getTextStatus, getTextType } from "../utils";
import { LIST_MODEL_TYPES, LIST_ROLES } from "@/core/constant";

export default defineComponent({
  components: { ObjectInformation },

  setup(_, { emit }) {
    const {
      revenueId,
      visible,
      close,
      data,
      isFetching,
      fetch
    } = useLoadingData();
    const { user, hasRole } = usePermission();

    const isShowButtonReject = eagerComputed(() => {
      if (data.value.sub_status === REVENUE_STATUS.DA_HUY) return false;

      if (hasRole("admin")) return true;

      if (hasRole(LIST_ROLES.accountant.name)) {
        // cho phép huỷ trong 7 ngày
        return dayjs().isBefore(dayjs(data.value.created_at).add(7, "days"));
      }

      if (hasRole(LIST_ROLES.storeManager.name)) {
        // check phiếu của chi nhánh cửa hàng trưởng
        const isCurrentBranch = data.value.branch_id === user.value.branch_id;

        // check phiếu trong ngày
        const isCreatedToday = dayjs(data.value.created_at).isToday();

        // check nếu type === chi thì status === đang chờ mới được huỷ
        const isType =
          data.value.type === TYPES.TYPE_CHI
            ? data.value.sub_status === REVENUE_STATUS.DANG_CHO
            : true;

        return isCurrentBranch && isCreatedToday && isType;
      }

      return false;
    });

    const isShowButtonConfirm = eagerComputed(() => {
      if (data.value.sub_status !== REVENUE_STATUS.DANG_CHO) return false;

      if (hasRole("admin") || hasRole(LIST_ROLES.accountant.name)) return true;

      if (hasRole(LIST_ROLES.storeManager.name)) {
        // check phiếu của chi nhánh cửa hàng trưởng
        const isCurrentBranch = data.value.branch_id === user.value.branch_id;

        // check loại thanh toán trả góp hoặc chuyển khoản chi nhánh
        const isTraGop =
          data.value.kind === KIND.THU_TRA_GOP ||
          data.value.kind === KIND.THU_COD;
        const isChuyenKhoan =
          data.value.kind === KIND.THU_CHUYEN_KHOAN &&
          data.value.receiver_bank?.type === "BRANCH";
        const isQuetThe=
          data.value.kind === KIND.THU_QUET_THE;

        return isCurrentBranch && (isTraGop || isChuyenKhoan || isQuetThe);
      }

      return false;
    });

    const { onResolve, onReject, isLoading } = useTransactionAction();

    const handleResolveData = () => {
      onResolve(data.value, () => {
        fetch();
        console.log("reload");
        emit("reload");
      });
    };

    return {
      revenueId,
      visible,
      close,
      data,
      isFetching,
      user,
      isShowButtonReject,
      isShowButtonConfirm,
      onResolve,
      onReject,
      isLoading,
      handleResolveData
    };
  },
  data() {
    return {
      LIST_MODEL_TYPES,
      REVENUE_STATUS,
      statusColors: {
        1: "amber",
        2: "green",
        3: "red"
      }
    };
  },
  methods: {
    get,
    getTextKind,
    getTextStatus,
    getTextType,
    async viewDetailOrder(item) {
      await this.$store.dispatch("ORDER/getOrderById", item.id);

      // Get order payments
      await this.$store.dispatch("ORDER/getOrderPaymentTransactions", item.id);

      // Get order participant
      await this.$store.dispatch(
        "INVOICE_PARTICIPANT/getInvoiceParticipantsOfInvoice",
        {
          modelName: "App\\Models\\Order",
          modelId: item.id
        }
      );

      this.$modal.show({
        name: "modal-order"
      });
    },
    async viewDetailSaleReceipt(item) {
      await this.$store.dispatch("SALE_RECEIPT/getSaleReceiptById", item.id);

      this.$modal.show({
        name: "modal-sale-receipts"
      });

      this.$store.dispatch(
        "SALE_RECEIPT/getSaleReceiptPaymentTransactions",
        item.id
      );
    },
    async viewDetailCustomerReturn(item) {
      await this.$store.dispatch(
        "CUSTOMER_RETURN/getCustomerReturnById",
        item.id
      );

      await this.$store.dispatch(
        "CUSTOMER_RETURN/getCustomerReturnTransactions",
        item.id
      );

      this.$modal.show({
        name: "modal-customer-return"
      });
    },
    viewDetailTransactionable(item, type) {
      if (type === LIST_MODEL_TYPES.ORDER) {
        this.viewDetailOrder(item);
      } else if (type === LIST_MODEL_TYPES.HOA_DON_BAN) {
        this.viewDetailSaleReceipt(item);
      } else if (type === LIST_MODEL_TYPES.KHACH_TRA_HANG) {
        this.viewDetailCustomerReturn(item);
      }
    }
  }
});
const useLoadingData = () => {
  const instance = getCurrentInstance();
  const revenueId = useRouteQuery("revenueId", null);
  const visible = computed({
    get() {
      return Boolean(revenueId.value);
    },
    set(value) {
      if (!value) {
        close();
      }
    }
  });
  const close = () => {
    revenueId.value = null;
    data.value = null;
  };
  const { fetch, data, isFetching } = useFetchData(
    () => {
      return TransactionService.getTransactionById(revenueId.value);
    },
    {
      autoResetData: false,
      transform: ({ data: response }) => response.data,
      enabled: () => Boolean(revenueId.value),
      reject: () => {
        close();
        instance.proxy.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: "Tải dữ liệu không thành công"
          }
        });
      }
    }
  );
  const open = async () => {
    await fetch();
  };
  watch(
    revenueId,
    value => {
      if (value) {
        open();
      } else {
        close();
      }
    },
    { immediate: true }
  );
  return {
    revenueId,
    visible,
    close,
    data,
    fetch,
    isFetching
  };
};
</script>
